import React, { useEffect, useState, useContext, MouseEvent } from "react"
import { ITerraIcon } from "../../../utils/types";
import { Link } from 'react-router-dom'

const TerraIcon = (props: ITerraIcon) => {

    const {
        clickable, name, paths, size, type,
        className, flip, onClick, url, style, isActive
    } = props;

    useEffect(() => {

    }, [])

    const fireOnClick = (e: MouseEvent<HTMLAnchorElement>) => {

        e.preventDefault()

        if (onClick) {
            onClick(e)
        }

    }

    const computeIconClass = (): string => {

        let result: string = `ts-icon terra-${name}`;

        if (!clickable) {
            result = `ui-relative ${result} ${className ? className : ''}`
        }

        if (flip) {
            result = `${result} flip`
        }

        if (type) {
            result = `${result} ${type}`
        }

        if (isActive) {
            result = `${result} active`
        }

        if (paths.length <= 0) {
            result = `${result} fs-${size.toString()}`
        }

        return result;

    }

    return (
        <>

            {
                clickable &&

                <>
                    <Link
                        onClick={(e) => onClick ? fireOnClick(e) : {}}
                        to={`${url ? url : ''}`}
                        className={`ui-relative ${className ? className : className}`}
                        style={style ? style : {}}>
                        <span className={computeIconClass()}>
                            {
                                paths.length > 0 &&
                                paths.map((path, index) => {
                                    return <i key={index} className={`${path} fs-${size.toString()}`}></i>
                                })
                            }
                        </span>
                    </Link>
                </>

            }

            {
                !clickable &&
                <>
                    <span
                        className={computeIconClass()}
                        style={style ? style : {}}>
                        {
                            paths.length > 0 &&
                            paths.map((path, index) => {
                                return <i key={index} className={`${path} fs-${size.toString()}`}></i>
                            })
                        }
                    </span>
                </>
            }


        </>
    )
};

export default TerraIcon;
