import {
    SET_LOADING,
    UNSET_LOADING,
    GET_BUSINESS,
    GET_WALLET,
    GET_ACCOUNTS,
    GET_ACCOUNT,
    SET_COUNT,
    SET_TOTAL,
    SET_PAGINATION,
    SET_RESPONSE,
    SET_SEARCH,
    SET_WALLET,
    GET_TRANSACTIONS,
    GET_TRANSACTION,
    GET_BENEFICIARIES,
    GET_BENEFICIARY,
    GET_BANKS,
    GET_BUSINESS_BANK,
    GET_BUSINESS_BANKS,
    GET_BILLERS,
    GET_BILL_CATEGORIES,
    GET_MOBILE_PLANS,
    GET_PRODUCTS,
    GET_PRODUCT,
    SET_SPLITS,
    GET_PAYMENT_LINKS,
    GET_PAYMENT_LINK,
    GET_VACE_OVERVIEW,
    SET_BANK,
    GET_SETTLEMENTS,
    GET_SETTLEMENT,
    GET_CHARGEBACKS,
    GET_CHARGEBACK,
    GET_REFUNDS,
    GET_REFUND,
    GET_PROVIDERS,
    GET_PROVIDER,
    GET_INVOICES,
    GET_INVOICE,
    SET_INVOICE_ITEMS,
    GET_BUSINESSES,
    GET_VACE_GRAPH,
    GET_BUSINESS_SETTINGS,
    SET_ANALYTICS,
    SET_FILTER_OPTIONS,
    SET_CURRENCY,
    SET_BUSINESS_MERCHANTS
} from '../types';


const reducer = (state: any, action: any) => {

    switch (action.type) {

        case GET_VACE_OVERVIEW:
            return {
                ...state,
                overview: action.payload,
                loading: false
            }
        case GET_VACE_GRAPH:
            return {
                ...state,
                graph: action.payload,
                loading: false
            }
        case GET_BUSINESS:
            return {
                ...state,
                business: action.payload,
                loading: false
            }
        case GET_BUSINESSES:
            return {
                ...state,
                businesses: action.payload,
                loading: false
            }
        case GET_BUSINESS_SETTINGS:
            return {
                ...state,
                settings: action.payload
            }
        case GET_WALLET:
            return {
                ...state,
                wallet: action.payload,
                loading: false
            }
        case GET_MOBILE_PLANS:
            return {
                ...state,
                dataPlans: action.payload,
                loading: false
            }
        case GET_BILLERS:
            return {
                ...state,
                billers: action.payload,
                loading: false
            }
        case GET_BILL_CATEGORIES:
            return {
                ...state,
                bills: action.payload,
                loading: false
            }
        case GET_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload,
                loading: false
            }
        case GET_ACCOUNT:
            return {
                ...state,
                account: action.payload,
                loading: false
            }
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                loading: false
            }
        case GET_PRODUCT:
            return {
                ...state,
                product: action.payload,
                loading: false
            }
        case GET_SETTLEMENTS:
            return {
                ...state,
                settlements: action.payload,
                loading: false
            }
        case GET_SETTLEMENT:
            return {
                ...state,
                settlement: action.payload,
                loading: false
            }
        case GET_CHARGEBACKS:
            return {
                ...state,
                chargebacks: action.payload,
                loading: false
            }
        case GET_CHARGEBACK:
            return {
                ...state,
                chargeback: action.payload,
                loading: false
            }
        case GET_REFUNDS:
            return {
                ...state,
                refunds: action.payload,
                loading: false
            }
        case GET_REFUND:
            return {
                ...state,
                refund: action.payload,
                loading: false
            }
        case GET_INVOICES:
            return {
                ...state,
                invoices: action.payload,
                loading: false
            }
        case GET_INVOICE:
            return {
                ...state,
                invoice: action.payload,
                loading: false
            }
        case SET_BUSINESS_MERCHANTS:
            return {
                ...state,
                merchants: action.payload
            }
        case SET_INVOICE_ITEMS:
            return {
                ...state,
                invoiceItems: action.payload,
                loading: false
            }
        case GET_PROVIDERS:
            return {
                ...state,
                providers: action.payload,
                loading: false
            }
        case GET_PROVIDER:
            return {
                ...state,
                provider: action.payload,
                loading: false
            }
        case SET_SPLITS:
            return {
                ...state,
                splits: action.payload,
                loading: false
            }
        case GET_PAYMENT_LINKS:
            return {
                ...state,
                paymentLinks: action.payload,
                loading: false
            }
        case GET_PAYMENT_LINK:
            return {
                ...state,
                paymentLink: action.payload,
                loading: false
            }
        case GET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload,
                loading: false
            }
        case GET_TRANSACTION:
            return {
                ...state,
                transaction: action.payload,
                loading: false
            }
        case GET_BENEFICIARIES:
            return {
                ...state,
                beneficiaries: action.payload,
                loading: false
            }
        case GET_BENEFICIARY:
            return {
                ...state,
                beneficiary: action.payload,
                loading: false
            }
        case GET_BUSINESS_BANKS:
            return {
                ...state,
                banks: action.payload,
                loading: false
            }
        case GET_BUSINESS_BANK:
            return {
                ...state,
                bank: action.payload,
                loading: false
            }
        case SET_WALLET:
            return {
                ...state,
                wallet: action.payload,
                loading: false
            }
        case SET_BANK:
            return {
                ...state,
                bank: action.payload,
                loading: false
            }
        case SET_COUNT:
            return {
                ...state,
                count: action.payload,
                loading: false
            }
        case SET_TOTAL:
            return {
                ...state,
                total: action.payload,
                loading: false
            }
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
                loading: false
            }
        case SET_SEARCH:
            return {
                ...state,
                search: action.payload,
                loading: false
            }
        case SET_ANALYTICS:
            return {
                ...state,
                analytics: action.payload,
                loading: false
            }

        case SET_CURRENCY:
            return {
                ...state,
                currency: action.payload,
                loading: false
            }
        case SET_FILTER_OPTIONS:
            return {
                ...state,
                filter: action.payload
            }
        case SET_RESPONSE:
            return {
                ...state,
                response: action.payload,
                loading: false
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }

}

export default reducer;